html{
  -webkit-transform: translate3d(0,0,0);
}

h1{
    font-family:"Open Sans",sans-serif;
    font-size: 35px;
}

.header{
    padding-top: 10px;
    padding-bottom: 0px;
}


.center{
    text-align: center;
}